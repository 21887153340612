<template>
    <v-container>
        <v-row class="d-flex">
            <v-btn @click="$router.go(-1)" class="mt-3">
                <v-icon>mdi-arrow-left</v-icon>
                Back
            </v-btn>
        </v-row>
        <v-row>
            <v-col cols="12">
                <full-land
                    v-if="nft.id"
                    :item="nft"
                    :isOwner="isOwner"
                    :active-offer="activeOffer"
                ></full-land>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {apiServiceVue} from "../services/apiService.vue";
import FullLand from "../components/assets/lands/full-land";
import {OfferStatus} from "@/classes/market/OfferStatus";
import MarketService from "@/services/marketService";
import {AssetType} from "@/classes/asset/AssetType";

export default {
    components: {FullLand},
    beforeRouteUpdate(to, from, next) { // for smooth refresh page when changing lands
        this.id = parseInt(to.params.id)
        this.nft = {};
        this.init();
        next();
    },
    data() {
        return {
            id: parseInt(this.$route.params.id),
            nft: {},
            landOffers: [],
            marketService: undefined,
        };
    },
    mounted: function () {
        this.init();
        window.addEventListener("reload-view", this.init);
    },
    destroyed() {
        window.removeEventListener("reload-view", this.init);
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isOwner() {
            return this.nft.owner?.toLowerCase() === this.web3.address?.toLowerCase();
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        activeOffer() {
            return this.landOffers
                .find(offer => offer.status === OfferStatus.AVAILABLE)
        },

    },
    methods: {
        init() {
            this.marketService = new MarketService();
            this.getLand(this.id);
            this.getAssetOffers();
        },
        getLand(id) {
            const url = process.env.VUE_APP_LAND_NFT_SERVER_URL + 'many/'+ id;
            apiServiceVue.request(url).then(nft => {
                if (nft) {
                    this.nft = nft.at(0);
                }
            });
        },
        getAssetOffers() {
            this.landOffers = [];
            this.marketService.getOffersFor(AssetType.LAND, this.id)
                .then(offers => this.landOffers = offers);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
