var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "d-flex" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mt-3",
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-left")]), _vm._v(" Back ")],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.nft.id
                ? _c("full-land", {
                    attrs: {
                      item: _vm.nft,
                      isOwner: _vm.isOwner,
                      "active-offer": _vm.activeOffer,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }